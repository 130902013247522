import { useTranslation } from 'react-i18next';
import { cnb } from 'cnbuilder';

import { useAppDispatch, useAppSelector } from 'Hooks/redux-hooks';

import { setItemsPerPage } from 'Store/app/actions';

import Select from 'Components/@Base/Select';

import styles from './ItemsPerPageSelector.module.scss';

type ItemsPerPageSelectorProps = {
  containerClassName?: string;
  selectClassName?: string;
};

const SELECT_OPTIONS = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '15',
    value: 15,
  },
  {
    label: '30',
    value: 30,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

const ItemsPerPageSelector = ({ containerClassName, selectClassName }: ItemsPerPageSelectorProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { itemsPerPage } = useAppSelector((state) => state.app);

  const handleSelectItemsPerPage = (selected: { value: number }) => {
    dispatch<any>(setItemsPerPage(selected.value));
  };

  return (
    <div className={cnb(styles.Container, containerClassName)}>
      <span className={styles.Label}>{t('Pozycji na stronę')}:</span>

      <Select
        className={cnb(styles.Select, selectClassName)}
        options={SELECT_OPTIONS}
        value={itemsPerPage}
        onChange={handleSelectItemsPerPage}
        isClearable={false}
        isSearchable={false}
      />
    </div>
  );
};

ItemsPerPageSelector.defaultProps = {
  containerClassName: '',
  selectClassName: '',
};

export default ItemsPerPageSelector;
