import { createSelector } from 'reselect';

import { RootState } from 'Store/store';

import { prepareFilters } from 'Utils/common';
import { prepareGroupsFiltersForTags } from 'Store/coordinatorGroups/helpers';

const getGroupsFilters = (state: RootState) => state.coordinatorGroups.filters;
const getHalls = (state: RootState) => state.app.halls;
const getEvents = (state: RootState) => state.app.events;
const getStatuses = (state: RootState) => state.app.order_statuses;
const getAccounts = (state: RootState) => state.app.accounts;
const getPayStatuses = (state: RootState) => state.app.pay_statuses;
const getSources = (state: RootState) => state.app.sources;

export const selectGroupsFilters$ = createSelector([getGroupsFilters], (filters) => {
  return prepareFilters(filters);
});

export const selectGroupsFiltersForTags$ = createSelector(
  [getGroupsFilters, getHalls, getEvents, getStatuses, getAccounts, getPayStatuses, getSources],
  (filters, halls, events, statuses, accounts, pay_statuses, sources) => {
    return prepareGroupsFiltersForTags(filters, halls, events, statuses, accounts, pay_statuses, sources);
  },
);
