import { Accordion, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { cnb } from 'cnbuilder';
import { useAppSelector } from 'Hooks/redux-hooks';

import TableCell from 'Components/@Shared/TableCell';
import { IoMdChatboxes } from 'react-icons/io';

import { selectGroupsFilters$ } from 'Store/coordinatorGroups/selectors';
import { store } from 'Store/store';

import { MdExpandLess, MdExpandMore } from 'react-icons/all';
import styles from 'Components/@Styles/Table.module.scss';
import { formatLocalFromWithoutSeconds } from 'Utils/common';
import { useHistory } from 'react-router-dom';

const GroupsTable = ({ TABLE_STRUCTURE, dialogs }: any) => {
  const { t } = useTranslation();
  const filters = useAppSelector((state) => selectGroupsFilters$(state));
  const history = useHistory();

  return (
    <div className="mt-3">
      <ReactTooltip className={styles.Tooltip} place="top" multiline />
      <div className={cnb(styles.TableRow, styles.TableRow__header)}>
        <div className="row">
          {Object.keys(TABLE_STRUCTURE).map((key) => (
            <TableCell
              key={key}
              className={TABLE_STRUCTURE[key].className}
              icon={filters[TABLE_STRUCTURE[key].sortKey ?? 1] === 'desc' ? MdExpandMore : MdExpandLess}
              onClick={
                typeof TABLE_STRUCTURE[key].onClick === 'function'
                  ? () => TABLE_STRUCTURE[key].onClick(store)
                  : undefined
              }
            >
              {t(TABLE_STRUCTURE[key].header)}
            </TableCell>
          ))}
        </div>
      </div>
      <Accordion>
        {dialogs.dialogs.map((row) => {
          return (
            <Card key={row.id} className={styles.TableCard}>
              <div className={styles.ContentRow}>
                <TableCell
                  className={cnb(TABLE_STRUCTURE.created_at.className, styles.TableCell)}
                  cellHeader={t(TABLE_STRUCTURE.created_at.header)}
                  useEllipsis={TABLE_STRUCTURE.created_at.useEllipsis}
                >
                  <span>{formatLocalFromWithoutSeconds(row.created_at)}</span>
                </TableCell>
                <TableCell
                  className={cnb(TABLE_STRUCTURE.schema_id.className, styles.TableCell)}
                  cellHeader={t(TABLE_STRUCTURE.schema_id.header)}
                  useEllipsis={TABLE_STRUCTURE.schema_id.useEllipsis}
                >
                  <span>{row.schema_id}</span>
                </TableCell>
                <TableCell
                  className={cnb(TABLE_STRUCTURE.score.className, styles.TableCell)}
                  cellHeader={t(TABLE_STRUCTURE.score.header)}
                  useEllipsis={TABLE_STRUCTURE.score.useEllipsis}
                >
                  <span>{row.score}</span>
                </TableCell>
                <TableCell
                  className={cnb(TABLE_STRUCTURE.description.className, styles.TableCell)}
                  cellHeader={t(TABLE_STRUCTURE.description.header)}
                  useEllipsis={TABLE_STRUCTURE.description.useEllipsis}
                >
                  <span data-border="true" data-background-color="black" data-tip={row.description}>
                    <span className="text-nowrap">{row.description}</span>
                  </span>
                </TableCell>

                <TableCell
                  className={cnb(TABLE_STRUCTURE.actions.className, styles.TableCell)}
                  cellHeader={t(TABLE_STRUCTURE.actions.header)}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <IoMdChatboxes
                      onClick={() => history.push({ pathname: `/conversations/${row.id}`, state: { row } })}
                      style={{
                        cursor: 'pointer',
                      }}
                      fontSize="large"
                    />
                  </div>
                </TableCell>
              </div>
            </Card>
          );
        })}
      </Accordion>
      {/* FOOTER TABLE ROW WITH TOTALS */}
      {/* <div className={styles.ContentRow_Total_value}>
        <TableCell
          className={cnb(TABLE_STRUCTURE.customer.className, styles.TableCell)}
          useEllipsis={TABLE_STRUCTURE.customer.useEllipsis}
        >
          <span className="font-weight-bold">{t('Suma')}</span>
        </TableCell>

        <TableCell className={cnb(TABLE_STRUCTURE.event.className, styles.TableCell)} />

        <TableCell className={cnb(TABLE_STRUCTURE.hall.className, styles.TableCell)} />

        <TableCell
          className={cnb(TABLE_STRUCTURE.term.className, styles.TableCell)}
          useEllipsis={TABLE_STRUCTURE.term.useEllipsis}
        />

        <TableCell
          className={cnb(TABLE_STRUCTURE.people.className, styles.TableCell)}
          useEllipsis={TABLE_STRUCTURE.people.useEllipsis}
        />
        <TableCell
          className={cnb(TABLE_STRUCTURE.status.className, styles.TableCell)}
          useEllipsis={TABLE_STRUCTURE.status.useEllipsis}
        />
        <TableCell
          className={cnb(TABLE_STRUCTURE.pay_status.className, styles.TableCell)}
          useEllipsis={TABLE_STRUCTURE.pay_status.useEllipsis}
        />
        <TableCell
          className={cnb(TABLE_STRUCTURE.order_value.className, styles.TableCell)}
          cellHeader={t(TABLE_STRUCTURE.order_value.header)}
        >
          <span className="font-weight-bold text-nowrap">
            {total_value.toLocaleString('pl-de')} {'PLN'}
          </span>
        </TableCell>
        <TableCell
          className={cnb(TABLE_STRUCTURE.paid_amount.className, styles.TableCell)}
          cellHeader={t(TABLE_STRUCTURE.paid_amount.header)}
        >
          <span className="font-weight-bold text-nowrap">
            {total_paid.toLocaleString('pl-de')} {'PLN'}
          </span>
        </TableCell>

        <TableCell className={cnb(TABLE_STRUCTURE.actions.className, styles.TableCell)} />
        <TableCell className={cnb(TABLE_STRUCTURE.button.className, 'text-center')} />
      </div> */}
    </div>
  );
};

export default GroupsTable;
