import API from 'Lib/axios';
import { Dispatch } from '@reduxjs/toolkit';

import { RootState } from 'Store/store';

import { GROUPS_ENDPOINTS } from 'Constants/endpoints';

import { formDataToString } from 'Utils/common';

import * as actionTypes from 'Store/coordinatorGroups/actionTypes';
import { selectGroupsFilters$ } from 'Store/coordinatorGroups/selectors';

export const toggleGroupsFiltersSidebarAction = (filtersOpen: boolean) => ({
  type: actionTypes.TOGGLE_FILTERS_SIDEBAR,
  payload: {
    filtersOpen,
  },
});

export const toggleGroupsFiltersSidebar = (filtersOpen?: boolean) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    if (typeof filtersOpen !== 'undefined') {
      dispatch(toggleGroupsFiltersSidebarAction(filtersOpen));
    } else {
      const { filtersOpen: open } = getState().coordinatorGroups;

      dispatch(toggleGroupsFiltersSidebarAction(!open));
    }
  };
};

/** GROUPS ############################################################################################################ */
export const setGroupsAction = (groups: Array<{}>, total: number) => ({
  type: actionTypes.SET_GROUPS,
  payload: {
    groups,
    total,
  },
});

export const setGroupsCurrentPageAction = (currentPage: number) => ({
  type: actionTypes.SET_CURRENT_PAGE,
  payload: {
    currentPage,
  },
});

export const setGroupsFiltersAction = (filters: {}) => ({
  type: actionTypes.SET_FILTERS,
  payload: {
    filters,
  },
});

export const setGroupsFiltersParamsAction = (filtersParams: {}) => ({
  type: actionTypes.SET_FILTERS_PARAMS,
  payload: {
    filtersParams,
  },
});

export const resetGroupsFiltersAction = () => ({
  type: actionTypes.RESET_FILTERS,
});

export const setGroupsReadyAction = () => ({
  type: actionTypes.SET_GROUPS_READY,
});

export const clearGroupsAction = () => ({
  type: actionTypes.CLEAR_GROUPS,
});

export const fetchGroupsWithFilters = (skip: number, limit: number, paramsString: any) => {
  const params = paramsString.length ? `?${paramsString}` : '';
  return API.get(`${GROUPS_ENDPOINTS.GROUPS}${params}`, {
    headers: {
      skip,
      limit,
    },
  });
};

export const getGroupsWithFilters = (skip: number, limit: number, paramsString: any, init = false) => {
  return (dispatch: Dispatch) => {
    fetchGroupsWithFilters(skip, limit, paramsString).then((res) => {
      const { groups, total } = res.data;
      dispatch(setGroupsAction(groups, total));
      if (init) {
        dispatch(setGroupsReadyAction());
      }
    });
  };
};

export const changeGroupsCurrentPage = (page: number) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const filters = selectGroupsFilters$(getState());
    const { itemsPerPage } = getState().app;
    const paramsString = formDataToString(filters);
    const skip = (page - 1) * itemsPerPage;

    dispatch(setGroupsCurrentPageAction(page));
    dispatch(setGroupsFiltersParamsAction(paramsString));
    dispatch<any>(getGroupsWithFilters(skip, itemsPerPage, paramsString));
  };
};

export const removeGroupsFilter = (id: number) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(setGroupsFiltersAction({ [id]: '' }));

    const filters = selectGroupsFilters$(getState());
    const { itemsPerPage } = getState().app;
    const paramsString = formDataToString(filters);

    dispatch(setGroupsCurrentPageAction(1));
    dispatch(setGroupsFiltersParamsAction(paramsString));
    dispatch<any>(getGroupsWithFilters(0, itemsPerPage, paramsString));
  };
};

export const resetGroupsFilters = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(resetGroupsFiltersAction());

    const filters = selectGroupsFilters$(getState());
    const { itemsPerPage } = getState().app;
    const paramsString = formDataToString(filters);

    dispatch(setGroupsCurrentPageAction(1));
    dispatch(setGroupsFiltersParamsAction(paramsString));
    dispatch<any>(getGroupsWithFilters(0, itemsPerPage, paramsString));
  };
};

export const sortByDate = () => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const filters = selectGroupsFilters$(getState());
    filters.date_sort = filters.date_sort === 'desc' ? 'asc' : 'desc';

    const { itemsPerPage } = getState().app;
    const paramsString = formDataToString(filters);

    dispatch(setGroupsCurrentPageAction(1));
    dispatch(setGroupsFiltersParamsAction(paramsString));
    dispatch<any>(getGroupsWithFilters(0, itemsPerPage, paramsString));
  };
};
