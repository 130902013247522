import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RCPagination from 'rc-pagination';

import { formatUnderscoreIso } from 'Utils/common';

import styles from './Pagination.module.scss';

type PaginationProps = {
  current: number;
  total: number;
  defaultPageSize: number;
  pageSize: number;
  onChange: (page: number, pageSize: number) => void;
};

const Pagination = ({ current, ...rest }: PaginationProps) => {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [current]);

  useEffect(() => {
    const getLocale = async () => {
      const lang = formatUnderscoreIso(i18n.language);
      const localeInfo = await import(`rc-pagination/lib/locale/${lang}`);

      return localeInfo;
    };

    getLocale().then((localeInfo) => {
      setLocale(localeInfo);
    });
  }, [i18n.language]);

  return (
    <div className={styles.PaginationContainer}>
      {locale !== null && <RCPagination {...rest} current={current} locale={locale} />}
    </div>
  );
};

export default Pagination;
