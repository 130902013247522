import ButtonWrapper from 'Components/@Shared/ButtonWrapper';
import PageContainer from 'Components/@Shared/PageContainer';
import PageHeader from 'Components/@Shared/PageHeader';
import pageHeaderStyles from 'Components/@Styles/PageHeader.module.scss';
import { useLocation } from 'react-router-dom';

const ConversationDetails = () => {
  const location = useLocation<any>();
  const obj = location.state.row;
  return (
    <PageContainer>
      <>
        <PageHeader>
          <div className={pageHeaderStyles.PageHeader}>
            Szczegóły rozmowy
            <div className={pageHeaderStyles.PageHeader__actions}>
              {/* <ItemsPerPageSelector /> */}
              {/* <ToggleFiltersButton handleToggle={handleToggleFiltersSidebar} /> */}
            </div>
          </div>
        </PageHeader>
        {obj.user_conversation_dialogs.map((message) => (
          <div
            key={message.id}
            style={{
              display: 'flex',
              justifyContent: message.direction === 1 ? 'flex-start' : 'flex-end',
              margin: '10px 0',
            }}
          >
            <div
              style={{
                maxWidth: '60%',
                padding: '10px',
                borderRadius: '10px',
                backgroundColor: message.direction === 1 ? '#FF6A74' : '#024a7c',
                color: message.sender === 1 ? '#000' : '#fff',
              }}
            >
              <span>{message.text}</span>
            </div>
          </div>
        ))}
        <ButtonWrapper
          className="ml-auto mt-9"
          alignment="center"
          label="Wróć do listy"
          type="button"
          buttonType="secondary"
          onClick={() => window.history.back()}
        />
      </>
    </PageContainer>
  );
};

export default ConversationDetails;
