import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from 'Hooks/redux-hooks';
import { cnb } from 'cnbuilder';

import PageContainer from 'Components/@Shared/PageContainer';
import PageHeader from 'Components/@Shared/PageHeader';
import PageContent from 'Components/@Shared/PageContent';
import Block from 'Components/@Shared/Block';
import NoResults from 'Components/@Shared/NoResults';
import TableSummaryItem from 'Components/@Shared/TableSummaryItem';
import Loader from 'Components/@Shared/Loader';
import GroupsTable from 'Containers/Conversations/components/GroupsTable/GroupsTable';

import pageHeaderStyles from 'Components/@Styles/PageHeader.module.scss';
import styles from 'Components/@Styles/Table.module.scss';
import API from 'Lib/axios';
import Pagination from 'Components/@Base/Pagination';
import ItemsPerPageSelector from 'Components/@Shared/ItemPerPageSelector';

const TABLE_STRUCTURE = {
  created_at: {
    header: 'Data utworzenia',
    className: 'col-lg',
    useEllipsis: true,
  },
  schema_id: {
    header: 'Schemat',
    className: 'col-lg',
    useEllipsis: true,
  },
  score: {
    header: 'Ocena',
    className: 'col-lg',
    useEllipsis: true,
  },
  description: {
    header: 'Opis',
    className: 'col-lg-3',
    useEllipsis: true,
  },
  actions: {
    header: '',
    className: cnb(styles.TableCell_Action_Groups, 'col-lg-2'),
  },
};
const Conversations = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { filtersActive } = useAppSelector((state) => state.coordinatorGroups);
  const { itemsPerPage } = useAppSelector((state) => state.app);

  const [dialogs, setDialogs] = useState<any>([]);
  const [total, setTotal] = useState<any>(0);
  const [isReady, setisReady] = useState<any>(false);
  const [currentPage, setCurrentPage] = useState<any>(1);

  const skip = (currentPage - 1) * itemsPerPage;

  useEffect(() => {
    API.get('https://mementor.dialplan.systems/api/user/bot/dialogs', {
      headers: {
        skip,
        limit: itemsPerPage,
      },
    }).then((response) => {
      setDialogs(response.data);
      setTotal(response.data.total);
      setisReady(true);
    });
  }, [dispatch, itemsPerPage, skip]);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <PageContainer>
      <>
        <PageHeader>
          <div className={pageHeaderStyles.PageHeader}>
            {t('Rozmowy')}
            <div className={pageHeaderStyles.PageHeader__actions}>
              <ItemsPerPageSelector />
              {/* <ToggleFiltersButton handleToggle={handleToggleFiltersSidebar} /> */}
            </div>
          </div>
        </PageHeader>

        <PageContent>
          <Block className="mt-0" element="div">
            <>
              {/* <FiltersContainer /> */}

              <div className="d-flex align-items-center">
                <TableSummaryItem label={`${t('Suma')}`}>{total}</TableSummaryItem>

                {/* <ButtonWrapper
                  className="ml-auto"
                  alignment="center"
                  label={t('Dodaj grupę')}
                  type="button"
                  buttonType="primary"
                  onClick={handleAddGroup}
                /> */}
              </div>

              {isReady ? (
                dialogs.dialogs.length > 0 && (
                  <>
                    <GroupsTable TABLE_STRUCTURE={TABLE_STRUCTURE} dialogs={dialogs} />
                    <Pagination
                      current={currentPage}
                      total={total}
                      defaultPageSize={itemsPerPage}
                      pageSize={itemsPerPage}
                      onChange={handleChangePage}
                    />
                  </>
                )
              ) : (
                <Loader minHeight="300px" />
              )}

              {isReady && dialogs.dialogs.length <= 0 && <NoResults hasActiveFilters={filtersActive} />}
            </>
          </Block>
        </PageContent>
      </>
    </PageContainer>
  );
};

export default Conversations;
