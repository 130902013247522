import { cnb } from 'cnbuilder';

import styles from 'Components/@Shared/TableSummaryItem/TableSummaryItem.module.scss';

type TableSummaryItemProps = {
  className?: string;
  label: string;
  children: JSX.Element | number;
};

const TableSummaryItem = ({ className, label, children }: TableSummaryItemProps) => {
  if (!children) {
    return null;
  }

  return (
    <div className={cnb(styles.Item, className, 'py-2')}>
      <span>{label}</span>
      <strong className="ml-1">{children}</strong>
    </div>
  );
};

TableSummaryItem.defaultProps = {
  className: '',
};

export default TableSummaryItem;
